@import "../variables";

/*      REMOVING TABS HEADER        */
.react-tabs__tab-list{
    display: none;
}

.profile-page {
    overflow-x: hidden;
    .our-info {
        width: 100%;
        color: $text;
        .img-fondo {
            height: 10rem;
            max-height: 10rem;
            width: 100%;
            max-width: 100%;
            object-fit: cover;
        }
        .information {
            .photo-information-person {
                display: flex;
                flex-direction: row;
                margin-left: 4rem;
                #photo-pencil{
                    position: relative;
                    :hover{
                        background-color: $blue;
                        .pencil{
                            color: white;
                            border-radius: 50%;
                        }
                    }
                }
                .photo-person {
                    border: 0.4rem solid white;
                    box-shadow: 0 0 0.3rem rgba(195, 195, 195, 0.849);
                    width: 10rem;
                    height: 10rem;
                    margin-top: -5rem;

                    background-color: white;
                }
                .pencil-photo-profile{
                    display: flex;
                    align-items: center;
                    justify-content: center;    
                    background-color: white;
                    height: 25px;
                    width: 25px;
                    border-radius: 50%;
                    position: absolute;
                    bottom: 10px;
                    right: 10px;
                    color: $blue;
                }
                .information-person {
                    display: flex;
                    flex-direction: column;
                    margin: 0.5rem 0 0 1rem;
                    span {
                        width: 100%;
                        font-size: 0.9rem;
                        font-weight: 500;
                    }
                    svg {
                        height: 1.2rem;
                        margin-right: 0.3rem;
                        fill: $blue;
                    }
                }
            }
        }
    }

    .profile-information {
        margin-left: 5rem;
        margin-top: 1rem;
        height: 100%;
        p {
            display: block;
            font-weight: 550;
            margin-top: 0.8rem;
            margin-bottom: 0.8rem;
        }
        .information-contact-person {
            display: flex;
            flex-flow: nowrap column;
            margin-bottom: 1.3rem;
            div {
                display: grid;
                grid-template-columns: 1fr 5fr;
                font-size: 0.8rem;
                list-style: none;
                gap: 1rem;
                margin-bottom: 0.25rem;
                &.about-information-person .input-component{
                    div{
                        grid-column-start: 1;
                        grid-column-end: 3;
                    }
                    span{
                        display: none;
                    }
                }
                span {
                    font-weight: 600;
                }
                p {
                    margin: 0;
                    font-weight: 400;
                    a {
                        text-decoration: none;
                        color: $blue;
                    }
                }

                @media screen and (max-width: 1300px) {
                    grid-template-columns: 2fr 5fr;
                }
                @media screen and (max-width: $mobile) {
                    grid-template-columns: 4fr 6fr;
                }

            }
        }
        @media screen and (max-width: $mobile) {
            margin-left: 2rem;
        }    
    }
}
#profession {
    color: $blue;
    font-size: 0.8rem;
}
#information-button {
    align-items: start;
    Button {
        margin-top: 0.5rem;
        font-size: 0.8rem;
        border: 1px solid $blue;
        margin-right: 0.8rem;
    }
}
#email-person{
    display: block;
    height: 100%;
    word-break: break-all;
}
#input-change-photo{
    height: 25px;
    width: 25px;
    border-radius: 50%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    opacity: 0 ;
    font-size: 0;
    cursor: pointer !important;
}

@media screen and (max-width: $mobile) {
    .photo-information-person{
        width: 100% !important;
        display: flex ;
        align-items: center;
        margin-left: 0 !important;
        justify-content: center !important;
        flex-direction: column !important;
        .information-button{
            width: 100% !important;
            display: flex;
            flex-direction: column !important;
            align-items: center !important;
        }
    }
    #information-button {
        button {
            margin-bottom: 0.5rem;
            margin-right: 0 !important;
        }
    }
    #input-about{
        grid-column-end: 5;
        grid-column-start: 1;
    }    
}    

.text-area-report{
    margin-right: 0 !important;
}

// html
// <p>{user.last_name}</p>
