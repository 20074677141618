@import "../variables";

.sidebar-header {
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: flex-start;

    padding: 1rem;

    &>h5 {
        font-weight: 600;
        color: $text;
    }

    &>img {
        width: 7rem;
        height: 7rem;
        margin-right: 1rem;
        border-radius: $radius / 2;
        -webkit-border-radius: $radius / 2;
        -moz-border-radius: $radius / 2;
        -ms-border-radius: $radius / 2;
        -o-border-radius: $radius / 2;

        object-fit: cover;
    }
}

.panel-lesson {
    & h6 {
        font-weight: 700;

        color: $text;
    }

    &>h6 {
        font-size: 1.25rem;
    }

    & p {
        font-size: 0.9rem;
        color: $text;
    }

    video {
        max-width: 100%;
        width: 100%;
        margin-bottom: 2rem;
        border-radius: $radius / 2;
        -webkit-border-radius: $radius / 2;
        -moz-border-radius: $radius / 2;
        -ms-border-radius: $radius / 2;
        -o-border-radius: $radius / 2;

        &::-internal-media-controls-download-button {
            display: none;
        }

        &::-webkit-media-controls-enclosure {
            overflow: hidden;
        }

        &::-webkit-media-controls-panel {
            width: calc(100% + 30px);
            /* Adjust as needed */
        }
    }

    .controls-lesson-prev-next {
        width: 100%;
        margin: 2rem 0 5rem 0;

        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;
    }

    .actual-module-lesson {

        padding: 1rem;
        border: 1px solid rgba(0, 0, 0, .1);

        h6 {
            width: 100%;
            margin-bottom: 1rem;

            display: inline-flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-between;

            div {
                display: inline-flex;
                align-items: center;

                i {
                    margin-right: .5rem;
                }

                span {
                    font-weight: 400;
                }
            }
        }

        p {
            display: inline-block;
            width: 100%;
            margin-bottom: 0;
        }

        border-radius: calc($radius / 2);
    }
}


.pdf-viewer-lesson {
    background-color: $red;
    color: white;

    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: space-between;

    width: auto;
    padding: .7rem 1rem;

    text-decoration: none;

    border-radius: $radius / 2;
    -webkit-border-radius: $radius / 2;
    -moz-border-radius: $radius / 2;
    -ms-border-radius: $radius / 2;
    -o-border-radius: $radius / 2;

    margin-bottom: 1rem;

    border-bottom: 5px solid rgba(0, 0, 0, .5);

    /*&:hover{
        color: white;

        background-color: rgba(211, 75, 52, 1);

        border-bottom: 5px solid rgba(255,255,255,.3);
    }*/

    p {
        margin: 0;
        color: white;
        font-weight: 600;
    }

    &>div {
        & * {
            margin-left: .5rem;
            color: white;
            text-decoration: none;

            cursor: pointer;

            &:hover {
                opacity: .8;
            }
        }
    }
}

.lesson-pdf {}

.quizz-page-content-lesson {
    display: flex;
    flex-flow: nowrap column;
    align-items: center;

    .quizz-completed-label {
        margin-bottom: 1rem;
    }

    .quizz-results-lesson {
        display: flex;
        flex-flow: nowrap row;
        align-items: stretch;
        justify-content: center;

        gap: 1rem;

        width: 100%;

        small {
            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-between;


            padding: .25rem 1rem;
            border-radius: 50rem;
            color: white;

            &[color="green"] {
                background-color: $green;
            }

            &[color="red"] {
                background-color: $red;
            }

            &>div {
                display: flex;
                flex-flow: nowrap column;
                align-items: center;
                justify-content: center;

                margin-left: 1rem;

                small {
                    padding: .15rem .5rem;

                    border-radius: 0 !important;

                    &:first-child {
                        border-bottom: 1px solid white;
                    }
                }
            }
        }
    }

    .correct-responses-quizz-aftersend {
        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        margin: 2rem 0;

        & .collapse, & .collapsing{
            width: 100%;
        }

        & .results-quizz {
            display: flex;
            flex-flow: nowrap column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;

            &>div {
                width: 100%;
                display: flex;
                flex-flow: nowrap column;
                align-items: flex-start;
                justify-content: flex-start;

                margin-bottom: .75rem;
                padding-bottom: .75rem;

                border-bottom: 1px solid rgba(0, 0, 0, .1);

                i {
                    margin-left: auto;

                    &[color="green"] {
                        color: $green;
                    }

                    &[color="red"] {
                        color: $red;
                    }

                    &::before {
                        font-weight: 600 !important;
                    }
                }

                span {
                    margin-bottom: .25rem;
                    font-weight: 400;
                    color: $text;

                    b {
                        font-weight: 400 !important;
                        font-size: .8rem;
                    }
                }

                small {
                    width: 100%;
                    display: block;

                    b {
                        margin-left: .25rem;
                        font-weight: 500;
                    }

                    &:nth-child(2) {
                        display: inline-flex;
                        flex-flow: nowrap row;
                        align-items: center;

                    }
                }
            }
        }

        .see-results {
            cursor: pointer;
            margin-bottom: 1rem;

            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-between;

            width: 100%;
            font-weight: 600;
            color: $blue;

            i {
                &::before {
                    font-weight: 600 !important;
                }
            }
        }
    }
}