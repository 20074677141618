@import "../variables";

$card-sides-spacing: 0.5rem;

.pointer-card {
    cursor: pointer;
}

.card-component {
    width: inherit;
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: space-between;

    position: relative;

    background-color: white;
    padding-bottom: 1rem;

    border-radius: $radius;
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;

    border: 1px solid rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: $shadow;
    }

    & .yt-lite {
        background-position: center;

        button{
            display: none;
        }
    }
    & .iframe {
        width: 100%;
        height: 100%;

        border-radius: $radius !important;
    }

    &>.head-card.head-mini>* {
        height: 10rem !important;



        @media screen and (max-width: $mobile) {
            height: 8rem !important;
        }
    }

    &>.head-card {
        width: calc(100% - calc($card-sides-spacing * 2));
        max-width: calc(100% - calc($card-sides-spacing * 2));
        min-width: calc(100% - calc($card-sides-spacing * 2));

        margin: $card-sides-spacing $card-sides-spacing 0 $card-sides-spacing;

        &>* {
            max-height: 15rem;
            height: 15rem;

            width: 100%;
            min-width: 100%;
            max-width: 100%;

            border-radius: $radius;
            -webkit-border-radius: $radius;
            -moz-border-radius: $radius;
            -ms-border-radius: $radius;
            -o-border-radius: $radius;

            @media screen and (max-width: $mobile) {
                height: 13rem;
            }
        }
    }

    &>picture.author-logo {
        width: 3.5rem;
        height: 3.5rem;
        margin-top: -1.75rem;

        margin-left: $card-sides-spacing;

        background-color: white;
        box-shadow: $shadow;

        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        img,
        source {
            width: inherit;
            height: inherit;
            display: block;

            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }
    }

    &>.titles-info-card {
        height: -webkit-fill-available;
        flex-grow: 50;
    }

    & .title-card,
    &.card-center .title-card {
        padding: calc($card-sides-spacing / 2) $card-sides-spacing;
        font-size: 0.9rem;
        font-weight: 600;

        width: 100%;
        display: block;
        text-align: left;

        color: $text;
    }

    &.card-center .title-card {
        text-align: center !important;
    }

    & .description-card,
    &.card-center .description-card {
        padding: 0 $card-sides-spacing;
        font-size: 0.9rem;
        font-weight: 200;

        width: 100%;
        display: block;
        text-align: left;

        color: $text;
    }

    &.card-center .description-card {
        text-align: center !important;
    }

    &>.author-card {
        padding: calc($card-sides-spacing / 2) $card-sides-spacing;
        font-size: 0.9rem;
        font-weight: 400;

        width: 100%;
        display: block;
        text-align: left;

        color: $text;
    }

    &>.type-card {
        padding: 0 $card-sides-spacing;
        font-size: 0.9rem;
        font-weight: 800;
        text-transform: uppercase;

        width: 100%;
        display: block;
        text-align: right;

        color: $text;
    }

    @media screen and (max-width: $mobile) {
        &>.titles-info-card {
            &>span.title-card {
                font-size: 0.9rem;
            }
        }
    }
}

//See more component
.see-more {
    height: 4rem !important;
    max-height: 4rem;
    width: 4rem;

    padding: 0 !important;

    border-radius: 50rem;
    -webkit-border-radius: 50rem;
    -moz-border-radius: 50rem;
    -ms-border-radius: 50rem;
    -o-border-radius: 50rem;

    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: space-around;

    background-color: $blue;

    &>.icon-see-more {
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        width: 4rem;
        height: 4rem;
        min-width: 4rem;
        min-height: 4rem;
        max-width: 4rem;
        max-height: 4rem;

        background-color: white;

        display: flex;
        align-items: center;
        justify-content: center;

        border: 4px solid $blue;

        i {
            &::before {
                font-size: 2.5rem;
                font-weight: 1000 !important;
                color: $blue;
            }
        }
    }

    &>.titles-info-card {
        display: none;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: center;

        padding: 0.25rem 0.5rem;

        * {
            font-size: 0.9rem;
            text-align: center;
            margin: 0;
            padding: 0;
            color: white;

            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;
            transition: all 0.25s ease;
            -webkit-transition: all 0.25s ease;
            -moz-transition: all 0.25s ease;
            -ms-transition: all 0.25s ease;
            -o-transition: all 0.25s ease;

            transform: scale(0);
            -webkit-transform: scale(0);
            -moz-transform: scale(0);
            -ms-transform: scale(0);
            -o-transform: scale(0);
        }
    }

    transition: all 0.15s ease-in;

    &:hover {
        width: 100%;
        height: auto !important;

        .titles-info-card {
            display: flex;

            * {
                transform: scale(1);
                -webkit-transform: scale(1);
                -moz-transform: scale(1);
                -ms-transform: scale(1);
                -o-transform: scale(1);
            }
        }
    }
}