@import "../variables";

/*

*   BTN STTYLE
*
*/

.button-component{
    font-size: .8rem;
}

button.button-component[disabled]{
    cursor: default !important;
    &.btn-border{
        border: 2px solid $blue-disabled !important;
        background-color: $bg !important;
        color: $blue-disabled !important;
        &:hover{
            background-color: $bg !important;
            color: $blue-disabled !important;
        }
    }

    &.btn-no-border{
        background-color: $bg !important;
        color: $blue-disabled !important;
        &:hover{
            border: none !important;
        }
    }

    &.btn-solid{
        background-color: $blue-disabled !important;
        border: 2px solid $blue-disabled !important;
        &:hover{
            background-color: $blue-disabled !important;
            border: 2px solid $blue-disabled !important;
            color: white !important;
        }
    }
}

.btn-border{
    border-radius: 50rem;
    -webkit-border-radius: 50rem;
    -moz-border-radius: 50rem;
    -ms-border-radius: 50rem;
    -o-border-radius: 50rem;

    border: 2px solid $blue;
    background-color: white;

    color: $blue;
    text-align: center;
    
    padding: .25rem .75rem;
    margin: 0 .25rem;

    &.no-hover{
        &:hover{
            background-color: white !important;
            color: $blue !important;
        }
    }

    &:hover{
        background-color: $blue;
        color: white;

        i{
            &::before{
                color: white !important;
            }
        }
    }
}

.btn-no-border{
    border-radius: 50rem;
    -webkit-border-radius: 50rem;
    -moz-border-radius: 50rem;
    -ms-border-radius: 50rem;
    -o-border-radius: 50rem;

    border: 2px solid transparent;
    color: $blue;
    text-align: center;
    background-color: transparent;
    
    padding: .25rem .75rem;
    margin: 0 .25rem;

    &.no-hover{
        &:hover{
            border: 2px solid transparent !important;
        }
    }

    &:hover{
        border: 2px solid $blue;
    }
}

.btn-solid, .dropdown>button{
    font-size: .8rem;

    border-radius: 50rem;
    -webkit-border-radius: 50rem;
    -moz-border-radius: 50rem;
    -ms-border-radius: 50rem;
    -o-border-radius: 50rem;

    border: 2px solid $blue;

    background-color: $blue;
    color: white !important;
    text-align: center;
    
    padding: .25rem .75rem;
    margin: 0 .25rem;

    box-shadow: none !important;

    &.no-hover{
        &:hover, &:active, &:focus, &:focus-visible{
            border: 2px solid $blue !important;
            background-color: $blue !important;
            color: white !important;
        }
    }

    &:hover, &:active, &:focus, &:focus-visible{
        border: 2px solid $blue !important;
        background-color: white !important;
        color: $blue !important;
    }
}

.btn-radius-min{
    border-radius: $radius !important;
    -webkit-border-radius: $radius !important;
    -moz-border-radius: $radius !important;
    -ms-border-radius: $radius !important;
    -o-border-radius: $radius !important;
}