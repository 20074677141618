@import "../variables";

.policy-content{
    li{
        margin-left: 3rem !important;
    }
    h4{
        color: $blue;
        text-align: center;
    }
    h6{
        color: $blue;
    }
}