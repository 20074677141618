@import "../variables";

.custom-swiper-component {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    & > .custom-swiper-controls {
        width: inherit;
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;
        &>h6{
            color: $text;
            margin: 0;
        }

        &>.controls-swiper{
            display: inherit;
            align-items: inherit;
            justify-content: flex-end;
        
            & > i {
                background-color: transparent;

                font-size: 1rem;
                padding: 0.25rem 0.5rem;

                border-radius: 5px;
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                -ms-border-radius: 5px;
                -o-border-radius: 5px;

                cursor: pointer;

                &:hover {
                    background-color: $bg;
                }
                &:first-child {
                    margin-right: 0.75rem;
                }
                &::before {
                    font-weight: 800 !important;
                }
                &.disabled::before {
                    font-weight: 500 !important;
                }
            }
        }
    }

    & > .swiper-component {
        width: 100%;
        max-width: calc(100vw - $sides-spacing) !important;

        @media screen and (max-width: $tablet) {
            max-width: calc(100vw - $sides-spacing-tablet);
        }
        @media screen and (max-width: $mobile) {
            max-width: calc(100vw - $sides-spacing-mobile);
        }

        overflow-x: auto;

        &::-webkit-scrollbar {
            display: none !important;
        }

        & > .swiper-wrapper {
            flex-direction: row;
            align-items: stretch;
            margin: 1rem 0;

            & > .swiper-slide {
                height: auto;
                min-height: 100%;

                & > div {
                    height: 100%;
                }
            }
        }
    }
}

.see-more-card-swiper{
    display: flex;
    align-items: center;
    justify-content: center;
}