@import "../variables";

.modal-content {
    padding: 0rem 2rem 2rem 2rem;
    border-radius: 1rem !important;
}

.modal-header {
    border: none !important;
    padding-bottom: 0 !important;
    align-items: center !important;
    justify-content: center !important;

    .modal-title {
        display: flex !important;
        justify-content: center !important;
    }


    .close-button {
        display: flex;
        justify-content: end;
        position: absolute;
        right: 20px;
        cursor: pointer;
    }

    i {
        display: flex;
    }
}

.modal-body {
    display: flex;
    flex-direction: column !important;
    padding-top: 0 !important;

    p {
        margin-bottom: 2rem;
    }

    a {
        color: #086788;
        font-size: 0.8rem;
    }

    span {
        color: rgba(0, 0, 0, 0.7);
        font-weight: 500;
    }
    select{
        outline: none;
        padding-left: 8px;
        border-radius: 5px;
        border: 2px solid rgba(0, 0, 0, 0.192) !important;
    }
    input{
        border-radius: 5px;
        border: 2px solid rgba(0, 0, 0, 0.192) !important;
    }
    .react-datepicker__input-container {
        input {
            width: 100%;
            outline: none;
            padding-left: 8px;
            border-radius: 5px;
            border: 2px solid rgba(0, 0, 0, 0.192) !important;
        }
    }
}



.register-organization {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

@media screen and (max-width: 400px) {
    .modal-content {
        padding: 0rem 1rem 1rem 1rem;
    }
}