@import "../variables";

.statistics-page {
    padding: $sides-spacing $sides-spacing;

    .statistics {
        border: none;
        background-color: transparent;
        margin-top: 0;

        padding: 0;

        .form-header {
            border: none;
            background-color: transparent;
        }
    }

    .plot-message {
        display: flex;
        flex-flow: nowrap column;

        span {
            display: inline-flex;
            align-items: center;

            p {
                font-weight: 500;
                margin: 0;
                margin-left: 0.5ch;
            }
        }
    }

    .lesson-ranking {
        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;

        .lesson {
            margin: 0.25rem 0;

            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            span {
                font-weight: 400;
            }

            p {
                font-size: 0.9rem;
                font-weight: 200;
                margin: 0;
            }
        }
    }

    .tabs-components-statistics {
        .form {
            border-top: none !important;
        }
    }
}

.users-finded {
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    &>.table-header-users-finded,
    &>div {
        display: grid;
        grid-template-columns: minmax(0, 4fr) minmax(0, 4fr) minmax(0, 2fr);

        @media screen and (max-width: 500px) {
            grid-template-columns: minmax(0, 4fr) minmax(0, 2fr);
        }

        i {
            width: 100%;
            text-align: center;
            cursor: pointer;

            &:hover {
                color: $blue;
            }

            &::before {
                font-weight: 600 !important;
            }
        }

        &.person-inscripted-to-course {
            padding: 0.25rem 0.5rem;

            font-size: 0.9rem;

            &[color="bg"] {
                background-color: $bg;
            }

            span {
                overflow-x: hidden;
                text-overflow: ellipsis;
            }
        }

        .bi.bi-info {
            font-size: 1rem;
        }
    }

    &>.table-header-users-finded {
        text-align: center;

        &>* {
            border: 1px solid rgba(0, 0, 0, 0.1);
            font-weight: 600;
            color: $text;
        }
    }

    .collapse-user-lessons {
        padding: 0.25rem 1rem;

        &.show,
        &.collapsing {
            display: flex;
        }

        flex-flow: nowrap column;

        margin-top: 0.5rem;

        span {
            font-weight: 500;
            margin-bottom: 0.5rem;
        }

        p {
            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-between;

            font-weight: 300;
            margin-bottom: 0;

            padding: 0.15rem 0.25rem;

            border-bottom: 1px solid rgba(0, 0, 0, 0.1);

            small {
                font-size: 0.8rem;
                font-weight: 300;

                &[color="green"] {
                    color: $green;
                }

                &[color="red"] {
                    color: $red;
                }

                &[color="blue"] {
                    color: $blue;
                }

                i {
                    &[color="green"] {
                        color: $green;
                    }

                    &[color="red"] {
                        color: $red;
                    }

                    &[color="blue"] {
                        color: $blue;
                    }
                }
            }
        }
    }
}

.sorted-by-location {
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    div {
        display: grid;
        grid-template-columns: minmax(0, 7fr) minmax(0, 1fr);

        color: $text;

        &.titles-sorted-by-location {
            span {
                text-align: center;
                border: 1px solid rgba(0, 0, 0, 0.1);

                b {
                    font-weight: 600 !important;
                }
            }
        }

        span {
            padding: 0.25rem 0.5rem !important;
        }

        &:nth-child(even) {
            background-color: $bg;
        }
    }
}

.modal-quizz-review {
    .modal-content {
        padding: 1rem !important;
    }

    & .results-quizz {
        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;

        &>div {
            width: 100%;
            display: flex;
            flex-flow: nowrap column;
            align-items: flex-start;
            justify-content: flex-start;

            margin-bottom: .75rem;
            padding-bottom: .75rem;

            border-bottom: 1px solid rgba(0, 0, 0, .1);

            i {
                margin-left: auto;

                &[color="green"] {
                    color: $green;
                }

                &[color="red"] {
                    color: $red;
                }

                &::before {
                    font-weight: 600 !important;
                }
            }

            span {
                margin-bottom: .25rem;
                font-weight: 400;
                color: $text;

                b {
                    font-weight: 400 !important;
                    font-size: .8rem;
                }
            }

            small {
                width: 100%;
                display: block;

                b {
                    margin-left: .25rem;
                    font-weight: 500;
                }

                &:nth-child(2) {
                    display: inline-flex;
                    flex-flow: nowrap row;
                    align-items: center;

                }
            }
        }
    }
}