@import "../variables";

.sup-section-courses {
    height: 20rem;
    position: relative;
    & > picture {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 100%;
        height: inherit;

        display: block;
        z-index: -1;

        img {
            height: inherit;
            max-height: inherit;
            width: 100%;
            min-width: 100%;
            max-width: 100%;
            transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -moz-transform: scaleX(-1);
            -ms-transform: scaleX(-1);
            -o-transform: scaleX(-1);
        }
    }
    & > .sup-section-text {
        height: 100%;

        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;
        justify-content: center;

        & > h3,
        & > p {
            color: white;
            display: inline;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
        }

        & > h3 {
            max-width: 50%;
            display: inline;
            & * {
                display: inline;
            }
        }
    }

    @media screen and (max-width: $mobile) {
        padding-top: .5rem;
    }
}

/*      CATEGORIES      */
section.categories-courses-section {
    & > .categorie-cards {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        gap: 1rem;
        & > .categorie {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            cursor: pointer;
            border-radius: calc($radius / 2);
            -webkit-border-radius: calc($radius / 2);
            -moz-border-radius: calc($radius / 2);
            -ms-border-radius: calc($radius / 2);
            -o-border-radius: calc($radius / 2);
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                border-radius: calc($radius / 2);
                -webkit-border-radius: calc($radius / 2);
                -moz-border-radius: calc($radius / 2);
                -ms-border-radius: calc($radius / 2);
                -o-border-radius: calc($radius / 2);
                z-index: 1;
                filter: brightness(0.5);
                -webkit-filter: brightness(0.5);
            }
            span {
                display: block;
                text-align: center;
                width: 100%;
                font-size: 0.9rem;
                font-weight: 600;
                color: white;
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

                text-transform: uppercase;

                z-index: 5;
            }

            &:hover {
                box-shadow: $shadow;
            }
        }

        @media screen and (max-width: $mobile) {
            grid-template-columns: 1fr;
        }
    }
}

/*      BUSINESS        */
.business-courses {
    width: 100%;
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: center;
    
    .business-btns {
        width: 100%;

        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;

        & > img {
            width: 20rem;
            transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -moz-transform: scaleX(-1);
            -ms-transform: scaleX(-1);
            -o-transform: scaleX(-1);
}
        & > section > div {
            display: grid;
            grid-template-columns: 1fr 1fr;

            gap: 1rem;
        }

        @media screen and (max-width: $tablet) {
            & > img {
                display: none;
            }
        }

        @media screen and (max-width: 500px) {
            & > section > div {
                grid-template-columns: 1fr;
            }
        }
    }
}
