@import "../variables";

.content-about-us {
    .contactus-box {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        align-items: stretch;
        justify-content: center;

        .contact-information {
            flex: 1 1 40%;
            background-color: $blue;

            border-radius: $radius 0 0 $radius;
            -webkit-border-radius: $radius 0 0 $radius;
            -moz-border-radius: $radius 0 0 $radius;
            -ms-border-radius: $radius 0 0 $radius;
            -o-border-radius: $radius 0 0 $radius;

            padding: 1rem;

            display: inherit;
            flex-flow: nowrap column;
            align-items: flex-start;
            justify-content: space-around;

            color: white;

            & > .contacts {
                & > div {
                    display: flex;
                    flex-flow: nowrap row;
                    align-items: center;
                    justify-content: flex-start;

                    & > i {
                        margin-right: 0.5rem;
                    }
                }
                p {
                    margin-top: 1rem;
                    margin-bottom: 0.2rem;
                }
            }
        }

        .message-contact-us {
            flex: 1 1 60%;

            background-color: white;
            padding: 1rem;

            display: flex;
            flex-flow: nowrap column;
            align-items: stretch;
            justify-content: center;
            border-radius: 0 $radius $radius 0;
            -webkit-border-radius: 0 $radius $radius 0;
            -moz-border-radius: 0 $radius $radius 0;
            -ms-border-radius: 0 $radius $radius 0;
            -o-border-radius: 0 $radius $radius 0;
        }

        @media screen and (max-width: $mobile) {
            flex-flow: nowrap column;

            & > .contact-information {
                border-radius: $radius $radius 0 0;
                -webkit-border-radius: $radius $radius 0 0;
                -moz-border-radius: $radius $radius 0 0;
                -ms-border-radius: $radius $radius 0 0;
                -o-border-radius: $radius $radius 0 0;
            }
            & > .message-contact-us {
                border-radius: 0 0 $radius $radius;
                -webkit-border-radius: 0 0 $radius $radius;
                -moz-border-radius: 0 0 $radius $radius;
                -ms-border-radius: 0 0 $radius $radius;
                -o-border-radius: 0 0 $radius $radius;
            }
        }
    }
}

@media screen and (max-width: 875px) {
    .contactus-box {
        width: 90% !important
    }

    .contact-information {
        width: 60% !important ;
    }

    .message-contact-us {
        padding-top: 0.5rem !important;
    }
    .input-data {
        display: flex !important;
        flex-direction: column !important;
        flex-wrap: nowrap !important;
        align-content: center !important;
        justify-content: flex-start !important;
        margin-bottom: 0rem !important;
        Input {
            width: 90% !important;
            margin-bottom: 0.2rem !important;
        }
    }

    #input-message {
        margin-left: 0rem !important;
        width: 90% !important;
        height: 53% !important;
        p {
            margin-bottom: 0.3rem !important;
        }
    }
}

@media screen and (max-width: 595px) {
    .contactus-box {
        width: 100% !important
    }
}
@media screen and (max-width: 530px) {
}

.social-networks{
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-start;

    margin-top: 1rem;

    &>a{
        color: white;
        margin-right: 0rem;

        font-size: 1.25rem;
        width: 1.75rem;
        height: 1.75rem;
        
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            color: white;

            background-color: rgba(255, 255, 255, 0.2);
        }

        i{
            font-size: inherit;
        }
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
}
}