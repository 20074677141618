@import "../variables";

.newsletter-section {
    & > div {
        background-color: white;
        border: 1px solid $black-border;

        border-radius: $radius;
        -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
        -ms-border-radius: $radius;
        -o-border-radius: $radius;

        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: stretch;

        & > div {
            display: grid;
            grid-template-columns: 1fr 4fr;
            gap: 0.5rem;

            & > div.newsletter-content {
                grid-column-start: 2;

                display: flex;
                flex-flow: nowrap column;
                align-items: center;
                justify-content: center;
            }

            &:first-child {
                background-color: $blue;
                border-radius: $radius;
                -webkit-border-radius: $radius;
                -moz-border-radius: $radius;
                -ms-border-radius: $radius;
                -o-border-radius: $radius;
            }

            @media screen and (max-width: $mobile) {

                & > div.newsletter-content {
                    grid-column-start: 1;
                    grid-column-end: 4;
                }

                .picture-newsletter{
                    //display: none !important;
                    grid-column-start: 1;
                    grid-column-end: 4;
                    order: 2;

                    picture{
                        width: 4rem !important;
                        height: 4rem !important;

                        padding: .5rem !important;

                        margin: 0 0 -2rem 0 !important;
                        img{
                            width: 3rem !important;
                            height: 3rem !important;
                        }
                    }
                }

                .picture-newsletter ~ .newsletter-content{
                    padding-bottom: .5rem;
                }
            }
        }

        & .title-newsletter {
            padding: 1rem;
            * {
                color: white;
                display: block;
                width: 100%;
                text-align: center;
            }

            p {
                font-size: 0.9rem;
                margin-bottom: 0;
            }
            h5 {
                font-size: 1.25rem;
                font-weight: 600;
                margin-bottom: 0.25rem;
            }
        }

        & .content-newsletter {
            & > .picture-newsletter {
                width: 100%;
                max-width: 100%;
                padding: 0 1rem;

                display: flex;
                align-items: center;
                justify-content: center;

                grid-column-start: 1;

                align-self: flex-start;
                picture {
                    margin-top: -3.5rem;

                    background-color: white;
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;

                    padding: 1rem;
                    width: 7rem;
                    height: 7rem;

                    box-shadow: $shadow;

                    img {
                        width: 5rem;
                        height: 5rem;

                        object-fit: contain;
                    }
                }
            }
            
            & .newsletter-content{
                padding: 1.5rem 1rem;
                &>*{
                    width: 100%;
                    
                    &:first-child{
                        margin-bottom: .5rem;
                    }
                }
            }
        }
    }
}
