//VARIABLES

//Colors
$text: rgba(0,0,0,.7);

$blue: #086788;
$blue-disabled: #53a0ba;

$green: #11A65C;

$dark: #383838;

$light-blue: #4FBECD;

$red: #E94C1E;

$yellow: #FFCE05;

$bg-dark: #f1f1f1;
$bg: rgba(0,0,0,.05);


//Others
$black-border: rgba(0,0,0,.3);

$radius: 20px;
$shadow: 0 3px 6px rgba(0,0,0,.2);


//BREAKPOINTS
$tablet: 992px;
$mobile: 768px;
$mini: 400px;

//SPACING
$sides-spacing: 3rem;
$sides-spacing-tablet: 2rem;
$sides-spacing-mobile: 1rem;

//TRANSITION
$transition-time-type: .25s ease-in;
$duration-loader: 10s;

//SIZES
$header-height: calc(48px + 16px);
$mini-icon-size: 2rem;