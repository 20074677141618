@import "../variables";

footer {
    background: url("../../../public/assets/img/bg-footer.jpg");
    width: 100%;
    height: 100%;
    //padding: 4rem 3rem 0 3rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    color: white;
    box-sizing: border-box;
}

.footer-top {
    display: flex;
    justify-content: space-between;

    .text-left-footer {
        width: 42%;

        .text-fundacion-footer {
            line-height: 1.4rem;
            padding-bottom: 3rem;
            span {
                display: block;
                font-size: 1.6rem;
                line-height: 2.5rem;
            }
            .text-footer{
                margin-bottom: 0;
                display: inline;
            }
            p {
                font-size: 0.9rem;
                display: inline;
            }
        }
        .ver-mas{
            width: 3.7rem;
            font-weight: 600;
            display: inline;
            &:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .text-conoce-footer {
            a {
                color: white;
                font-weight: 700;

                &:hover {
                    color: #f2f2f2;
                }
            }
        }
    }

    .text-right-footer {
        .search-footer {
            height: 100%;
            ul{
                background-color: white;
                border-radius: 5px;
                text-decoration: none;
                padding-top: .1rem;
                padding-bottom: .2rem;
                padding-right: .2rem;
                li{
                    list-style: none;
                    &:first-child{
                        margin-top: .1rem;
                        display: block;
                    }
                    a{
                        text-decoration: none;
                    }
                    div{
                        color: $blue;
                        
                        &:hover{
                            padding-left: 0.4rem;
                            margin-left: -1rem;
                            border-radius: 5px;
                            background-color: $blue;
                            color: rgb(255, 255, 255);
                        }
                    }
                }
            }

            input {
                border: 1px solid rgba(255, 255, 255, 0.721);
                border-radius: 5px;
                height: 45px;
                width: 25rem;
                padding: 2px 10px 2px 10px;
                outline: 0;
                background-color: transparent;
                color: white;
                input[type="search"]:-webkit-search-decoration,
                input[type="search"]:-webkit-search-cancel-button,
                input[type="search"]:-webkit-search-results-button,
                input[type="search"]:-webkit-search-results-decoration {
                    display: none;
                }                &::placeholder {
                    color: rgba(255, 255, 255, 0.386);
                }

                
            }
        }
    }
}

hr {
    border: none;
    border-top: 2px solid;
    margin: 0 !important;
}

.footer-footer {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        display: block;
        text-align: center;
    }

    p {
        margin-left: 0.5rem;
    }
}

@media screen and (max-width: $tablet) {
    footer {
        //padding: 4rem $sides-spacing-tablet 0 $sides-spacing-tablet;

        .footer-top {
            flex-direction: column;

            .text-right-footer {
                padding-bottom: 2rem;

                .search-footer {
                    input {
                        width: 100%;
                    }
                }
            }

            .text-left-footer {
                width: 100%;

                .text-fundacion-footer {
                    padding-bottom: 2.5rem;

                    span {
                        display: block;
                        margin-bottom: 1rem;
                    }

                    p {
                        text-align: justify;
                    }
                }

                .text-conoce-footer {
                    margin-bottom: .5rem;
                }
            }
        }
    }
}

@media screen and (max-width: $tablet) {
    .text-fundacion-footer {
        p {
            font-size: 0.9rem;
        }
    }

    .text-conoce-footer {
        p {
            font-size: 0.9rem;
        }
    }

    .footer-footer {
        span {
            font-size: 0.9rem;
        }
    }

    .search-footer {
        input {
            &::placeholder {
                font-size: 0.9rem;
            }
        }
    }
}

.social-networks-footer{
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-start;

    &>a{
        color: white;
        margin-right: .25rem;

        font-size: 1.25rem;
        width: 1.75rem;
        height: 1.75rem;
        
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover{
            color: white;

            background-color: rgba(255, 255, 255, 0.2);
        }

        i{
            font-size: inherit;
        }
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
}
}