@import "../variables";

/*
*
*   SUP sECTION
**/
.sup-section-home {
    width: 100%;
    max-width: 100%;

    position: relative;
    picture {
        position: absolute;

        left: 0;
        top: 0;
        right: 0;

        width: 100%;
        max-width: 100%;
        height: 30rem;
        max-height: 30rem;

        display: block;
        z-index: -1;

        img {
            width: inherit;
            height: inherit;
            object-fit: cover;
            filter: brightness(50%);
            -webkit-filter: brightness(50%);
        }
    }

    .sup-section-text {
        width: 100%;
        height: 25rem;
        max-height: 25rem;
        display: flex;
        flex-flow: nowrap column;
        align-items: center;
        justify-content: center;

        & > * {
            width: 70%;
            height: auto;

            display: block;
            text-align: center;
            margin: 0;

            color: white;
        }

        & > h1 {
            font-weight: 800;

            margin-bottom: 1rem;
        }
        & > h5 {
            font-size: 1.5rem;
            font-weight: 600;

            margin-bottom: 1rem;
        }
        & > p {
            font-size: 1rem;
        }
        @media screen and (max-width: 400px) {
            & > * {
                width: 85%;
            }
            & > h1 {
                margin-bottom: 0.5rem;
            }
            & > h5 {
                margin-bottom: 0.5rem;
            }
        }
    }
}

/*
*
*   SKILLS
*
*/
.recrea-skills {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: -5%;

    & > div {
        width: 100%;
        max-width: 100%;
        background-color: $bg-dark;
        border: $radius;

        display: inherit;
        flex-flow: nowrap row;
        align-items: stretch;
        justify-content: space-between;

        border-radius: $radius;
        -webkit-border-radius: $radius;
        -moz-border-radius: $radius;
        -ms-border-radius: $radius;
        -o-border-radius: $radius;

        box-shadow: $shadow;

        .in-middle-skills {
            width: 10px;
            height: auto;
            background-color: white;

            padding: 0;
            
            box-shadow:inset 0 0px 6px rgba(0,0,0,.15);
        }

        & > div {
            max-width: 50%;
            width: 50%;

            height: 100%;
            display: inherit;
            flex-flow: nowrap column;
            align-items: center;
            justify-content: center;
            padding: 1rem;

            picture {
                width: 5rem;
                max-width: 5rem;

                img {
                    width: inherit;
                    max-width: inherit;
                }
            }

            span {
                font-weight: 600;
                color: $text;

                width: 100%;
                display: block;
                text-align: center;
                
                margin: 1rem 0 0.5rem 0;
            }
            p {
                font-size: .9rem;
                font-weight: 200;
                width: 100%;
                display: block;
                text-align: center;
            }

            @media screen and (max-width: $mobile) {
                padding: 1rem .25rem;
                span, p{
                    font-size: .9rem;
                }
            }
        }
    }
}


.about-us-home{
    
}

.books-home{
    display: flex;
    flex-flow: nowrap row;
    align-items: stretch;
    justify-content: space-between;

    &>.card-component{
        width: 50%;
    }

    @media screen and (max-width: $mobile) {
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: stretch;

        &>.card-component{
            width: 100%;

            margin: 1rem 0 !important;
        }
    }
}

.videos-home{
    margin: 2rem 0;
}