@import "../variables";

$height-card: 7rem;

.course-card-component {
    position: relative;
    display: flex;
    flex-flow: nowrap row;
    align-items: stretch;

    max-height: $height-card;
    margin: .75rem 0;

    border: 1px solid $black-border;
    background-color: white;

    cursor: pointer;

    &:hover {
        background-color: rgba(0, 0, 0, 0.01) !important;
    }

    img {
        max-width: $height-card;
        max-height: $height-card;
        min-width: $height-card;
        min-height: $height-card;
        width: $height-card;
        height: $height-card;
    }

    &:hover {
        &>div>.top-right {
            display: flex;
        }
    }

    i {

        &.bi-book,
        &.bi-play-fill,
        &.bi-patch-question {
            color: $blue;
            margin-right: .5rem;
        }
    }

    &>div {
        padding: 0.5rem 2rem 0.5rem 1rem;

        max-height: $height-card - 1rem;
        overflow-y: auto;

        &>.top-right {
            position: absolute;

            top: -0.75rem;
            right: -0.75rem;

            display: flex;
            flex-flow: nowrap column;
            align-items: center;
            justify-content: center;

            display: none;
        }

        span {
            font-weight: 400;
            font-size: 1rem;
        }

        p {
            font-weight: 200;
            font-size: 0.9rem;
            overflow-y: visible;
            margin-bottom: 0;
        }
    }

    &,
    &>img {
        object-fit: cover;

        border-radius: calc($radius / 2);
        -webkit-border-radius: calc($radius / 2);
        -moz-border-radius: calc($radius / 2);
        -ms-border-radius: calc($radius / 2);
        -o-border-radius: calc($radius / 2);
    }
}

.courses-manager {
    padding: 2rem $sides-spacing 1rem $sides-spacing;

    @media screen and (max-width: $mobile) {
        padding-right: calc($sides-spacing / 2);
        padding-left: calc($sides-spacing / 2);
    }

    .bi-pencil,
    .bi-card-list,
    .bi-x.admin-courses,
    .bi-plus.admin-courses {
        width: 1.5rem;
        height: 1.5rem;

        font-size: 1rem;
        color: white;

        display: flex;
        align-items: center;
        justify-content: center;

        cursor: pointer;
        margin-bottom: 0.25rem;

        border-radius: 5rem;
        -webkit-border-radius: 5rem;
        -moz-border-radius: 5rem;
        -ms-border-radius: 5rem;
        -o-border-radius: 5rem;
    }

    .bi-pencil {
        background-color: $green;

        &:hover {
            background-color: white;
            border: 1px solid $green;
            color: $green;
        }
    }

    .bi-card-list {
        background-color: $blue;

        &:hover {
            background-color: white;
            border: 1px solid $blue;
            color: $blue;
        }
    }

    .bi-x.admin-courses {
        background-color: $dark;

        &:hover {
            background-color: white;
            border: 1px solid $dark;
            color: $dark;
        }
    }

    .bi-plus.admin-courses {
        background-color: green;

        &:hover {
            background-color: white;
            border: 1px solid green;
            color: green;
        }
    }

    .tooltip-course-manager {
        overflow: hidden !important;
    }
}

.form {
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    padding: $sides-spacing;
    margin-top: 1rem;

    border-top: 1px solid $black-border;
    background-color: white;

    @media screen and (max-width: $mobile) {
        padding-right: calc($sides-spacing / 2);
        padding-left: calc($sides-spacing / 2);
    }

    &>* {
        margin-bottom: 0.75rem;
    }

    .form-header {
        display: flex;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;

        & .form-toolbar {
            width: 100%;
            display: flex;
            flex-flow: wrap row;
            align-items: stretch;
            justify-content: flex-start;

            column-gap: 0.5rem;
            row-gap: 0.5rem;

            i.bi-chevron-left {
                &::before {
                    font-weight: 800 !important;
                    color: $blue;
                }
            }
        }
    }

    .form-footer {
        display: flex;
        width: 100%;
        align-items: stretch;
        justify-content: flex-end;
        margin: 0.5rem 0 2rem 0;
    }
}

.modules-from-course {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid $black-border;

    padding: $sides-spacing;

    @media screen and (max-width: $mobile) {
        padding-right: calc($sides-spacing / 2);
        padding-left: calc($sides-spacing / 2);
    }
}

.admin-module,
.module-form {
    .rdp-picker {
        width: 100%;

        .rdp-text-overlay {
            font-size: 1.5rem;

            div {
                font-size: .9rem;
            }
        }

        .rdp-column {
            div {
                font-size: .9rem;
            }
        }

        hr {
            color: $blue;
        }
    }
}

.admin-module {
    background-color: white;

    padding-top: 1rem;
    border-top: 1px solid $black-border;

    padding: $sides-spacing;



    @media screen and (max-width: $mobile) {
        padding-right: calc($sides-spacing / 2);
        padding-left: calc($sides-spacing / 2);
    }
}

.content-added {
    background-color: $bg;

    display: flex;
    flex-flow: nowrap row;
    align-items: stretch;
    justify-content: space-between;
    padding: 1rem;

    .badge {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    span {
        small {
            font-size: 0.8rem;
            font-weight: 300;
        }
    }
}

.side-to-side {
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: space-between;

    &>div {
        &:first-child {
            max-width: calc(70% - 1rem);
            width: calc(70% - 1rem);

            margin-right: 1rem;

            &>* {
                margin-bottom: 0.5rem;
            }
        }

        &:last-child {
            max-width: calc(30% - 1rem);
            width: calc(30% - 1rem);
        }
    }

    @media screen and (max-width: $tablet) {
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: flex-start;

        &>div {
            width: 100% !important;
            max-width: 100% !important;

            &:last-child {
                margin-top: 2rem;
            }
        }
    }
}

.card-instructor-course-manager {
    max-width: 100%;
    position: relative;

    margin-bottom: 1rem;
    padding: 0.25rem;

    display: flex;
    align-items: center;

    border: 1px solid $black-border;

    background-color: white;

    .top-right {
        position: absolute;
        top: -0.75rem;
        right: -0.75rem;

        display: flex;
        flex-flow: nowrap column;

        i {
            width: 1.5rem;
            height: 1.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            cursor: pointer;

            &::before {
                font-weight: 800 !important;
                font-size: 1.25rem;
            }

            &.bi-x,
            &.bi-trash {
                color: red;
                border: 1px solid red;
                background-color: white;

                &:hover {
                    color: white;
                    background-color: red;
                }
            }

            border-radius: 10rem;
            -webkit-border-radius: 10rem;
            -moz-border-radius: 10rem;
            -ms-border-radius: 10rem;
            -o-border-radius: 10rem;
        }
    }

    .card-info-instructor {
        max-width: 100%;
        width: 100%;
        height: 7rem;

        display: flex;
        flex-flow: nowrap row;
        align-items: center;

        img {
            max-width: 5rem;
            width: 5rem;
            height: 5rem;
            border-radius: 50rem;
            -webkit-border-radius: 50rem;
            -moz-border-radius: 50rem;
            -ms-border-radius: 50rem;
            -o-border-radius: 50rem;
            object-fit: cover;
        }

        div {
            display: flex;
            flex-flow: nowrap column;
            align-items: flex-start;
            justify-content: center;

            margin-left: 0.5rem;

            span {
                font-weight: 400;
                font-size: 0.9rem;
            }

            p {
                font-weight: 200;
                font-size: 0.8rem;
            }
        }
    }

    border-radius: calc($radius / 2);
    -webkit-border-radius: calc($radius / 2);
    -moz-border-radius: calc($radius / 2);
    -ms-border-radius: calc($radius / 2);
    -o-border-radius: calc($radius / 2);
}

.temp-find-ins {
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    max-height: 10rem;
    overflow-y: auto;

    & span {
        display: inline-flex;
        flex-flow: nowrap column;
        align-items: flex-start;
        justify-content: space-between;

        cursor: pointer;

        font-size: 0.9rem;

        margin-bottom: 0.25rem;
        padding: 0.15rem;

        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        small {
            display: inline-block;
            text-align: end;
            margin-top: -0.25rem;
            width: 100%;
            font-size: 0.8rem;
            font-weight: 200;
        }

        &:hover {
            background-color: $bg;
        }
    }
}

.instructors-page {
    display: flex;
    flex-flow: nowrap column;

    .instructors-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));

        column-gap: 1rem;

        @media screen and (max-width: $mobile) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }
}

.all-instructors-showed {
    width: 100%;
    max-height: 5rem;
    overflow-y: auto;

    margin: 0.5rem 0;

    span {
        width: 100%;
    }
}


/** COURSE MANAGENTE**/

div[style="touch-action: pan-y; background: beige;"] {
    background-color: white !important;
}

.disable-select.list-item {
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-start;

    padding: .5rem 1rem;
    margin: .5rem 0;

    user-select: none;

    background-color: white;

    border: 1px solid $black-border;

    border-radius: calc($radius / 2);
    -webkit-border-radius: calc($radius / 2);
    -moz-border-radius: calc($radius / 2);
    -ms-border-radius: calc($radius / 2);
    -o-border-radius: calc($radius / 2);

    &>i.bi-grip-vertical {
        cursor: pointer;
    }

    &>i.bi {
        color: $blue;

        &.bi-play-fill,
        &.bi-book,
        &.bi-patch-question {
            margin-right: .5rem;
        }
    }

    &>.top-right-container {
        width: 0;
        height: 0;
        display: block;
        padding: 0;

        .top-right {
            display: none;
        }
    }

    &:hover {
        &>.top-right-container {

            .top-right {
                display: flex;
            }
        }
    }

}

.controls-btns {
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-end;
    gap: .5rem;
}