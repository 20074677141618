@import "variables.scss";

* {
    margin: 0;
    padding: 0;
}

h6 {
    margin: 0;
}

body {
    min-width: fit-content !important;
}

.contrast {
    background-color: $bg !important;
}

//@at-root
.description-section {
    font-size: 1rem;
    font-weight: 400;

    color: $text;
}

.title-section {
    color: $text;

    text-transform: uppercase;
    width: 100%;
    display: block;
    text-align: center;

    font-weight: 500;

    margin-bottom: 2rem;

    &.left {
        text-align: left !important;
    }
}

.subsection {
    margin: 2rem 0;

    &>.title-subsection {
        margin: 1rem 0 !important;
        font-size: 1rem;
        color: text;
    }
}

section {
    width: 100%;
    max-width: 100%;
    padding: 2rem 15%;

    &.mini-spacing {
        padding: 2rem 5% !important;
    }

    &.contrast {
        background-color: $bg;
    }

    &.sides-min {
        padding: 2rem 2rem !important;
    }

    &>.side-to-side {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        align-items: stretch;
        justify-content: space-between;

        &.invert {
            flex-flow: nowrap row-reverse;

            &>article {
                &:first-child {
                    margin-left: 1rem;
                    margin-right: 0;

                    * {
                        text-align: right;
                    }
                }

                &:last-child {
                    margin-right: 1rem;
                    margin-left: 0;
                }
            }
        }

        &.center {
            flex-flow: nowrap row;
            justify-content: center !important;

            &>article {
                margin-right: 0 !important;
                margin-left: 0 !important;

                width: 100%;
                max-width: 100%;
            }
        }

        &>article {
            max-width: 50%;
            width: 50%;

            p {
                font-size: 0.9rem;
                font-weight: 300;
                margin-bottom: 0;

                text-align: justify !important;

                span {
                    font-weight: 500;
                    font-size: 1.15rem;
                    color: $text;
                    display: inline-block;
                    width: 100%;
                    text-align: center;

                    * {
                        color: inherit;
                        text-decoration: none;
                    }
                }
            }

            picture {
                height: max-content;
                width: 100%;

                img {
                    width: inherit;
                    max-width: 100%;
                    height: 100%;

                    object-fit: cover;

                    border-radius: $radius;
                    -webkit-border-radius: $radius;
                    -moz-border-radius: $radius;
                    -ms-border-radius: $radius;
                    -o-border-radius: $radius;
                }
            }

            &:first-child {
                margin-right: 1rem;
                margin-left: 0;
            }

            &:last-child {
                margin-left: 1rem;
                margin-right: 0;
            }
        }
    }

    @media screen and (max-width: $tablet) {
        padding: 2rem 10%;
    }

    @media screen and (max-width: $mobile) {
        padding: 2rem 7%;

        &>.side-to-side {
            flex-flow: nowrap column-reverse;
            align-items: stretch;
            justify-content: stretch;

            &.invert {
                flex-flow: nowrap column-reverse;

                p {
                    text-align: center !important;
                }
            }

            article {
                width: 100%;
                max-width: 100%;
                margin: 0rem 0 1rem 0 !important;

                img {
                    height: 15rem;
                    max-height: 15rem;
                }

                p {
                    text-align: center;
                }
            }
        }
    }
}

/**/

/*
*
*   FIMAGE FIT
*
*/

picture.fit-cover {

    img,
    source {
        object-fit: cover !important;
    }
}

img.fit-cover {
    object-fit: cover !important;
}

picture.fit-contain {

    img,
    source {
        object-fit: contain !important;
    }
}

img.fit-contain {
    object-fit: contain !important;
}

/**
*
*   pointer STYLE
*
*/

.pointer {
    cursor: pointer;

    &:hover {
        background-color: $bg;
    }
}

/*
*
*   TOOLTIP
*
**/

.tooltip-style {
    z-index: 1000 !important;
}

/*
*
*   MODAL STYLES
*
*/

.form-content {
    background-color: white;
    padding: 3rem;
    border: 1px solid $bg;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;

    &.form-mini-sides {
        padding: 1rem !important;
    }

    &.form-side-to-side {
        display: grid;
        grid-template-columns: 10fr 6fr;
        gap: 2rem;
    }

    & .form-title {
        font-weight: 600;
        color: $text;
        margin-bottom: 1rem;
    }

    @media screen and (max-width: $tablet) {
        &.form-side-to-side {
            display: grid;
            grid-template-columns: 1fr;
        }
    }

    @media screen and (max-width: $mobile) {
        padding: 2rem;
    }
}

.form-header {}

.form-body {
    display: flex;
    flex-flow: nowrap column;
    align-items: stretch;
    justify-content: flex-start;

    &>* {
        margin-bottom: 1rem;
    }
}

.form-footer {
    padding: 0.5rem 0 !important;
}

.collapse {}

/*      TITLE       */
.title-regular {
    font-weight: 550;
    margin: 0.75rem 0;
    text-transform: uppercase;

    color: $text;
}


/*      BADGE       */
.badge {
    &[color="blue"] {
        background-color: $blue;
        color: white;
    }
}

/*      BUTTON ITH TITLE*/
.title-with-button {
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;

    &>div {
        &:first-child {
            display: flex;
            flex-flow: nowrap column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
        }

        &:last-child {
            display: flex;
            flex-flow: wrap row;
            align-items: center;
            justify-content: flex-end !important;
        }


    }

    @media screen and (max-width: $mobile) {
        flex-flow: wrap column;
        
        &>div:last-child{
            margin-top: .5rem;
            justify-content: flex-start !important;

            width: 100%;

            &>.input-component{
                width: 100%;
            }
        }
    }
}

/*      DROPDOWN        */

.collapse-content {
    display: none;
    padding: 10px;
    border: 1px solid #ccc;
    margin-top: 10px;
  }
  .collapsed {
    display: flex;
  }