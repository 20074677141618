@import "../variables";

.modal-content {
    padding: 0rem 2rem 2rem 2rem;
    border-radius: 1rem !important;
}

.modal-header {
    border: none !important;
    padding-bottom: 0 !important;
    align-items: center !important;
    justify-content: center !important;
    .modal-title {
        display: flex !important;
        justify-content: center !important;
    }

    .close-button {
        display: flex;
        justify-content: end;
        position: absolute;
        right: 20px;
    }

    i {
        display: flex;
    }

    i:hover {
        background-color: $bg;
        border-radius: 5px;
    }
}

.modal-body {
    display: flex;
    flex-direction: column !important;
    padding-top: 0 !important;

    span {
        margin: 1rem 0 0 0;
    }
    p {
        margin-bottom: 2rem;
    }

    a {
        color: $blue;
        font-size: 0.8rem;
    }
}

.login {
    margin-bottom: 0.5rem !important;
    width: 100%;
}
.btn-solid {
    margin: 0;
}
.btn-border {
    margin: 0;
}

.register {
    width: 100%;
}

@media screen and (max-width: 400px) {
    .modal-content {
        padding: 0rem 1rem 1rem 1rem;
    }
}

.slider-container {
    display: flex;
    width: 100%;
    height: 10rem;
    overflow-x: scroll;
}

.slider-container img {
    flex: 0 0 100%;
    width: 100%;
    object-fit: cover;
}
