@import "../variables";
/*      SIDEBASR VARIABLES      */
$sidebar-bg-color: #ffffff !default;
$sidebar-color: $text !default;
$sidebar-width: 350px !default;
$sidebar-collapsed-width: 60px;
$highlight-color: $blue !default;
$submenu-bg-color: #fff !default;
$submenu-bg-color-collapsed: #fff !default;
$icon-bg-color: white !default;
$icon-size: 35px !default;
$submenu-indent: 24px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-xxl: 1600px !default;

@import "~react-pro-sidebar/dist/scss/styles.scss";

$sides-spacing: 2rem;

.container-with-sidebar {
    width: 100%;
    max-width: 100vw;
    min-height: 15rem;
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: space-between;

    & > .pro-sidebar.collapsed ~ * {
        //width: calc(100vw - calc($sidebar-collapsed-width + 1.5rem));
        width: 100%;
    }
    & > *:last-child {
        //width: calc(100vw - calc($sidebar-width + 1.5rem));
        width: 100%;
    }

    @media screen and (max-width: $tablet) {
        position: relative;
        & > .pro-sidebar {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
        }
        & > *:last-child {
            margin-left: $sidebar-collapsed-width;
            //width: calc(100vw - calc($sidebar-collapsed-width + 1.5rem)) !important;
            //max-width: calc(100vw - calc($sidebar-collapsed-width + 1.5rem)) !important;
        }
    }
}

.pro-sidebar {
    padding: 0rem 0rem;
    height: 100%;
    border: 1px solid $black-border;

    max-width: $sidebar-width !important;

    position: sticky;
    top: $header-height;
    z-index: 50;

    &.collapsed {
        max-width: $sidebar-collapsed-width !important;
    }

    .pro-inner-item {
        padding: 0.5rem 0 !important;
    }

    .title-sidebar {
        width: 100%;
        padding: 0 0.5rem 0 $sides-spacing;

        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;

        border-bottom: 1px solid $black-border;

        h6 {
            color: $text;
            font-weight: 600;
            margin: 0;
        }

        i {
            color: $text;
            font-size: 1.75rem;
            cursor: pointer;
        }
    }

    .menu-element {
        & > .menu-title {
            width: 100%;
            padding: calc($sides-spacing / 4) 0.9rem calc($sides-spacing / 4) $sides-spacing;

            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-between;

            border-bottom: 1px solid $bg;

            & > span {
                color: $text;
                font-size: 0.9rem;
                font-weight: 500;
            }
            & > i {
                color: $text;
                font-size: 1rem;
                &::before {
                    font-weight: 1000 !important;
                }
            }
        }
        & > .menu-title-icon {
            width: 100%;

            padding: 1rem;

            display: flex;
            align-items: center;
            justify-content: center;

            & > i {
                color: $text;
                font-size: 1.15rem;
            }
        }
    }

    .collapse-subelements {
        .submenu-element {
            padding: calc($sides-spacing / 4) calc($sides-spacing / 2) calc($sides-spacing / 4) $sides-spacing ;
            border-bottom: 1px solid $bg;
            font-size: 0.9rem;
            font-weight: 300;

            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: space-between;

            i{
                display: flex;
                align-items: center;
            }
        }
    }

    .menu-selected {
        background-color: $bg;
    }

    &.collapsed {
        .title-sidebar {
            justify-content: center !important;
            padding: unset !important;
            h6 {
                display: none;
            }
        }
        .header-sidebar {
            display: none;
        }
    }

    @media screen and (max-width: $tablet) {
    }
}

.darken-sidebar-element {
    background-color: $bg;

    & > span {
        font-weight: 400;
        color: $text;
    }
}
