@import "../variables";

.quizz-component {

    margin: 1rem 0 !important;

    .form-to-edit {
        background-color: rgba(0, 0, 0, 0.05);
        display: flex;
        flex-flow: nowrap row;
        align-items: stretch;
        justify-content: space-between;
        padding: 1rem;

        span.badge{
            display: flex;
            flex-flow: nowrap row;
            align-items: center;
            justify-content: center;
        }
    }

}

.modal-preview-quizz {
    .modal-head-icon-x {
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 1rem;

        h5 {
            margin-bottom: 0;
        }

        i {
            font-size: 1.25rem;
        }

    }

    .modal-content {
        padding: 0 !important;

        form>div {
            &:last-child {
                margin-bottom: 0 !important;

                span.ant-form-item-children {
                    button {
                        &::after {
                            content: " (Guardar respuestas)";
                        }
                    }
                }
            }

        }

        .p-4 {
            small {
                margin-top: 1rem !important;
                display: block;
            }
        }
    }
}

.ant-list.ant-list-split {
    ul.ant-list-items {
        &>.ant-list-item {

            &:nth-child(5),
            &:nth-child(8),
            &:nth-child(10) {
                display: none !important;
            }
        }
    }
}

.react-form-builder.clearfix {
    &>div {
        display: grid;
        grid-template-columns: minmax(0, 3fr) minmax(0, 1fr);

        &>div {
            margin-top: 0 !important;

            &.react-form-builder-toolbar {
                li {
                    i {
                        display: none;
                    }
                }
            }
        }
    }

    & .react-form-builder-toolbar {
        svg {
            margin-right: .25rem;
            color: $blue;
        }

        ul {
            &>li {

                &:nth-child(22),
                &:nth-child(19),
                &:nth-child(24),
                &:nth-child(26),
                &:nth-child(27),
                &:nth-child(12),
                &:nth-child(13) {
                    display: none;
                }
            }
        }
    }

    & .Sortable {
        svg {
            margin-right: .25rem;
            color: $blue;
        }
    }
}

.react-form-builder-preview {

    * {
        font-family: "Poppins", sans-serif !important;
    }

    &>.edit-form {
        &>div {
            &>.clearfix {
                display: flex;
                flex-flow: nowrap row;
                align-items: center;
                justify-content: space-between;

                width: 100%;

                margin-bottom: 2rem;

                * {
                    margin: 0 !important;
                }

                &::after {
                    display: none !important;
                }

                &~.form-group,
                &~div>.form-group {
                    margin-bottom: .5rem;

                    padding-bottom: .5rem;

                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

                    &>label {
                        color: rgba(0, 0, 0, 0.7);
                        font-weight: 500;
                        margin-bottom: 0.5em;
                        font-size: .875rem;

                        &~* {
                            * {
                                font-size: 1rem;
                                font-weight: 300 !important;
                            }
                        }
                    }

                    &>.custom-control {
                        display: flex;
                        flex-flow: nowrap row;
                        align-items: center;
                        justify-content: flex-start;

                        &>*:first-child {
                            margin-right: .25rem;
                        }
                    }

                    &>label {
                        &[for="optionsApiUrl"] {
                            display: none;

                            &~* {
                                display: none;
                            }
                        }

                        &[for="correctAnswer"] {
                            display: none;

                            &~* {
                                display: none;
                            }
                        }
                    }
                }
            }


        }
    }
}

.react-form-builder-form {
    &>form {
        .SortableItem.rfb-item {
            padding: .5rem 0;
            margin: .5rem 0;

            border-bottom: 1px solid rgba(0, 0, 0, .1);

            .form-group {
                &>label {
                    color: rgba(0, 0, 0, 0.7);
                    font-weight: 500;
                    margin-bottom: 0.5em;
                    font-size: .875rem;

                    &~* {
                        * {
                            font-size: 1rem;
                            font-weight: 300 !important;
                        }
                    }

                    &~.range {
                        .slider.slider-horizontal {
                            width: 100% !important;
                        }
                    }
                }

                &>.custom-control {
                    display: flex;
                    flex-flow: nowrap row;
                    align-items: center;
                    justify-content: flex-start;

                    &>*:first-child {
                        margin-right: .25rem;
                    }
                }

                &>label {
                    &[for="optionsApiUrl"] {
                        display: none;

                        &~* {
                            display: none;
                        }
                    }

                    &[for="correctAnswer"] {
                        display: none;

                        &~* {
                            display: none;
                        }
                    }
                }
            }
        }

        .btn-toolbar {
            margin-top: 2rem !important;
            margin-bottom: 1rem !important;

            justify-content: flex-end !important;

            input {
                background-color: $blue;
                color: white;
            }
        }
    }
}