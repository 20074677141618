@import "../variables";

.autocomplete-root {
}

.autocomplete-dropdown-container {
    background-color: white;
    //border: 2px solid rgba(0, 0, 0, 0.192) !important;
    box-shadow: $shadow;

    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;

    max-height: 7rem;
    width: 100%;
    max-width: 100%;
    overflow-y: auto;

    & > div {
        cursor: pointer;
        padding: .25rem;

        border-bottom: 1px solid $bg;

        &:hover{
            background-color: $bg;
        }
        span {
            color: black;
            padding-left: .5rem;
        }
    }
}
