@import "../variables";

.material-symbols-outlined {
    user-select: none !important;
}

.input-component {
    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: center;

    & > span > small {
        color: $text;
        font-weight: 500;
        margin-bottom: 0.5em;
    }
    & > div {
        width: 100%;
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-between;

        .text-area-component {
            padding-left: 0.4rem !important;
        }

        .select-component {
            padding-left: 0.25rem !important;
        }

        input,
        .text-area-component,
        .select-component {
            padding-left: 0.5rem;
            color: black;
            width: 100%;
            border: none;
            border-bottom: 2px solid rgba(0, 0, 0, 0.192);
            box-shadow: none !important;
            background-color: transparent;

            &[type="file"] {
                border-bottom: none !important;
                margin-top: 0.4rem;
                padding-left: 0 !important;
            }
        }

        i {
            padding: 0.1rem 0.3rem 0 0.3rem;
            background-color: $bg-dark;
            border-radius: 7px;
            font-size: 1.2rem;
            &:hover {
                box-shadow: $shadow;
            }
        }
        span.material-symbols-outlined {
            margin-left: 0.5rem;
            color: $blue;
            font-weight: 800;
            font-size: 2em;
        }
    }
}

.border-input {
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border: 2px solid rgba(0, 0, 0, 0.192) !important;
}
#pass {
    margin-right: 0.5rem;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input, select, textarea{
    &:disabled{
        background-color: $bg !important;
        color: $text;
        opacity: .8;
    }
}

/*          RADIO BUTTON COMPONENT      */
.radiobutton-component{
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: 1rem;

    & p{
        margin: 0 0 0 .5rem !important;
        font-size: .9rem !important;

        a{
            font-size: inherit;
        }
    }

    input{
        width: 1rem;
        height: 1rem;
        border: 1px solid red;
    }
}

/**
QUILLJS
***/

.quill{
    display: flex;
    flex-flow: nowrap column !important;
    align-items: stretch;
    justify-content: flex-start;

    background-color: white;

    &>.ql-toolbar.ql-snow{
        width: 100%;
    }

    &>.ql-container.ql-snow{
        width: 100%;
        
    }
}