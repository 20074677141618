@import "../variables";

.course-page {

}

.sup-content-course {
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: stretch;

    margin-bottom: 1.5rem;

    *{
        font-size: .9rem;
    }

    .img-subscribe {
        display: inherit;
        flex-flow: nowrap column;
        align-items: stretch;
        justify-content: stretch;

        margin-right: 2rem;

        img {
            max-width: 15rem;
            width: 12rem;
            height: 12rem;
            border-radius: calc($radius / 2);
            -webkit-border-radius: calc($radius / 2);
            -moz-border-radius: calc($radius / 2);
            -ms-border-radius: calc($radius / 2);
            -o-border-radius: calc($radius / 2);

            margin-bottom: 0.5rem;
        }

        button {
            width: 100%;

            border-radius: calc($radius / 2);
            -webkit-border-radius: calc($radius / 2);
            -moz-border-radius: calc($radius / 2);
            -ms-border-radius: calc($radius / 2);
            -o-border-radius: calc($radius / 2);

            font-weight: 600;
        }
    }

    .course-content {
        display: inherit;
        flex-flow: nowrap column;
        align-items: flex-start;
        justify-content: flex-start;

        * {
            color: $text;
        }

        h5 {
            font-weight: 800;
            margin-bottom: 0;
        }

        span {
            margin-bottom: 1rem;
            font-size: 0.9rem;
            font-weight: 500;

            text-transform: uppercase;
        }
        p {
            margin-bottom: 0;
            font-weight: 300;
            font-size: 1rem;
        }
    }

    @media screen and (max-width: $mobile) {
        flex-flow: nowrap column;
        align-items: center;
        justify-content: flex-start;

        .img-subscribe{
            margin-right: 0;
            margin-bottom: 1rem;
        }

        .course-content{
            & *{
                display: block;
                width: 100%;
                text-align: center;
            }
        }
    }
}
.public-target-course {
    margin-bottom: 1.5rem;
    * {
        color: $text;
            font-size: .9rem;
    }

    span {
        display: block;
        margin-bottom: 0.5rem;
    }
}

.instructors-course, .organization-author-course {
    * {
        color: $text;
        font-size: .9rem;
    }

    display: flex;
    flex-flow: nowrap column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 1rem;

    &>span{
        margin-bottom: 1.5rem;
    }

    & > div {
        display: inherit;
        flex-flow: nowrap row;
        align-items: flex-start;
        justify-content: flex-start;

        margin-bottom: 1rem;

        img {
            width: 5rem;
            height: 5rem;
            object-fit: cover;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;

            //box-shadow: $shadow;
        }

        div {
            margin-left: 1rem;
            display: inherit;
            flex-flow: nowrap column;
            align-items: flex-start;
            justify-content: flex-start;

            span {
                font-weight: 500;
            }
            p {
                font-weight: 200;
            }
        }
    }
}

.divided-course-info{
    *{
        font-size: .9rem;
    }
    width: 100%;
    display: flex;
    flex-flow: nowrap row;
    align-items: flex-start;
    justify-content: space-between;

    @media screen and (max-width: $tablet) {
        flex-flow: nowrap column;
    }
}
.modules-program-course{
    & *{color: $text; font-size: .9rem;}
    &>span{
        display: block;
        margin-bottom: 1rem;
    }

    & .module-section{
        display: flex;
        flex-flow: nowrap column;
        align-items: flex-start;
        justify-content: flex-start;

        padding-bottom: 1rem;
        border-bottom: 1px solid $black-border;

        &>div{
            &:first-child{
                width: 100%;
                display: flex;
                flex-flow: wrap row;
                align-items: center;
                justify-content: space-between;

                margin-bottom: .5rem;

                & *{
                    margin: 0;
                }
                &>div{
                    display: inherit;
                    flex-flow: inherit;
                    align-items: inherit;
                    justify-content: flex-start;

                    &>i{
                        margin-right: .5rem;
                    }
                }
            }

            &.lesson-describers{
                display: flex;
                flex-flow: nowrap row;
                align-items: center;
                justify-content: flex-start;

                &>i{
                    &::before{
                        font-weight: 600;
                        color: $text;
                    }
                    margin-right: .5rem;
                }
            }
        }
    }
}
