@import "../variables";

header.header {
    display: flex;
    flex-flow: nowrap row;
    align-items: center;
    justify-content: space-between;

    padding: 0.5rem $sides-spacing;

    position: sticky;
    top: 0;

    background-color: white;
    z-index: 99;

    & > div:first-child {
        width: 100%;
        display: inherit;
        align-items: inherit;

        justify-content: space-between;
    }

    .logo-left {
        img {
            max-height: 3rem;
        }
    }

    .menu-header-desktop {
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: flex-end;

        ul {
            display: inherit;
            flex-flow: inherit;
            align-items: inherit;
            justify-content: inherit;

            margin: 0;
            padding: 0;
        }
    }

    .btn-collapse {
        display: none;
    }

    .login-area {
        display: flex;
        flex-flow: nowrap row;
        align-items: center;
        justify-content: space-around;
    }

    li {
        list-style: none;
        margin: 0 0.5rem;
        a {
            text-decoration: none;

            font-size: 1rem;
            font-weight: 500;
            color: $text;

            text-transform: uppercase;
        }

        div {
            height: 2px;
            width: 100%;
            margin-top: -2px;
            display: block;
            border-radius: 2px;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            -ms-border-radius: 5px;
            -o-border-radius: 5px;
            background-color: $text;

            visibility: hidden;

            transform: scaleX(0);
            -webkit-transform: scaleX(0);
            -moz-transform: scaleX(0);
            -ms-transform: scaleX(0);
            -o-transform: scaleX(0);

            transition: transform $transition-time-type;
            -webkit-transition: transform $transition-time-type;
            -moz-transition: transform $transition-time-type;
            -ms-transition: transform $transition-time-type;
            -o-transition: transform $transition-time-type;
        }
        &:hover {
            div {
                visibility: visible;
                transform: scaleX(1);
                -webkit-transform: scaleX(1);
                -moz-transform: scaleX(1);
                -ms-transform: scaleX(1);
                -o-transform: scaleX(1);
            }
        }
    }

    li.active {
        div {
            visibility: visible;
            transform: scaleX(1);
            -webkit-transform: scaleX(1);
            -moz-transform: scaleX(1);
            -ms-transform: scaleX(1);
            -o-transform: scaleX(1);
        }
    }

    .modal-body{
        padding: 0;
    }

    //On logged
    .logged-char{
        padding: .25rem !important;
        font-size: 1.25rem;
        line-height: 1.25rem;
        font-weight: 600;
        text-transform: uppercase;

        width: calc(.5rem + 1.5rem);
        height: calc(.5rem + 1.5rem);

        margin-left: 1rem;
    }

    @media screen and (max-width: $tablet) {
        padding: 0.5rem $sides-spacing-tablet;

        li {
            margin: 0 0.25rem;
        }
    }
    @media screen and (max-width: $mobile) {
        padding: 0.5rem $sides-spacing-mobile;

        flex-flow: nowrap column;
        align-items: stretch;

        .btn-collapse {
            display: block;
            font-size: 1.25rem;
            box-shadow: none;
        }
        .menu-header-desktop {
            display: none;
        }
        .menu-header {
            margin: 1rem 0 0 0;
            ul {
                padding: 0;
                margin: 0;

                li {
                    margin-bottom: 0.5rem;
                    width: min-content;
                    width: fit-content;
                }
            }
        }

        .login-area {
            font-size: 0.8rem;
            button {
                margin: 0;
            }
        }
    }

    @media screen and (max-width: 400px) {
        .logo-left{
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 2rem;
            }
        }

        .btn-solid{
            padding: .25rem .5rem;
        }
    }
}